// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "x_dz";
export var mvpAppDevCaseSection = "x_dK";
export var mvpAppDevChoiceSection = "x_dG";
export var mvpAppDevClientQuotes = "x_dL";
export var mvpAppDevCostSection = "x_dD";
export var mvpAppDevExpertiseSection = "x_dJ";
export var mvpAppDevIndustriesSection = "x_dx";
export var mvpAppDevPracticesSection = "x_dF";
export var mvpAppDevPrimeSection = "x_dv";
export var mvpAppDevProcessSection = "x_dB";
export var mvpAppDevProjLogoSection = "x_dH";
export var mvpAppDevServicesSection = "x_dw";
export var mvpAppDevTechSection = "x_dC";
export var mvpAppDevTypesSection = "x_dy";